











































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import OrderService from '@/common/services/order/order-service';
import { getUserId, getIsAdmin } from '@/common/functions/auth';
import { Delivery } from '@/common/models/delivery';
import displayMessage from '@/common/functions/display-message';

@Component({})
export default class deliveryManagment extends Vue {
  orderService!: OrderService;

  userId = -1;

  status = '';

  maxQuantity = 0;

  quantity = 0;

  newMaxQuantity = 0;

  newQuantity = 0;

  nextDeliveryDate = '';

  pauseUntilDate = '';

  newPausedUntilDate = '';

  showMaxQuantityModal = false;

  showMaxQuantityModalConfirmation = false;

  showQuantityModal = false;

  showQuantityModalConfirmation = false;

  showPauseModal = false;

  showPauseModalConfirmation = false;

  username = '';

  institution = '';

  count = 0;

  itemsPerPage = 10;

  currentPage = 1;

  headers = [
    {
      text: 'ID',
      value: 'id',
      description: 'deliveryId',
      sortable: true,
    },
    {
      text: 'Bestellung am',
      value: 'packaging_at',
      description: 'packagingDate',
      sortable: true,
    },
    {
      text: 'Menge',
      value: 'quantity',
      description: 'quantity',
      sortable: true,
    },
    {
      text: 'Adresse',
      value: 'address',
      description: 'address',
      sortable: true,
    },
    {
      text: 'Typ',
      value: 'type',
      description: 'orderType',
      sortable: true,
    },
    {
      text: 'Tracking',
      value: 'tracking_id',
      description: 'trackingNumber',
      sortable: true,
    },
  ];

  deliveries: Delivery[] = [];

  loading = true;

  isAdmin = true;

  beforeMount() {
    this.isAdmin = getIsAdmin();
    const savedUserId = getUserId();

    if (this.isAdmin && (!this.$route.query.userId || this.$route.query.userId === savedUserId)) {
      this.$router.push('/order-overview');
      return;
    }

    if (this.isAdmin && this.$route.query.userId) {
      this.userId = +this.$route.query.userId;
      return;
    }

    if (!this.isAdmin && savedUserId) {
      this.userId = +savedUserId;
    }
  }

  mounted() {
    this.orderService = new OrderService();
    this.fillUserOrdersData();
  }

  async fillUserOrdersData() {
    this.loading = true;

    if (this.userId !== -1) {
      const { data, message } = await this.orderService.getUserOrderAndDeliveries(+this.userId);

      if (data?.order) {
        this.status = data.order.paused_until ? 'Pausiert bis' : 'Aktiv';
        this.maxQuantity = data.order.max_quantity ? +data.order.max_quantity : 10000;
        this.newMaxQuantity = this.maxQuantity;
        this.quantity = data.order.quantity ?? this.maxQuantity;
        this.newQuantity = this.quantity;
        this.nextDeliveryDate = data.order.next_delivery_at ?? '';
        this.pauseUntilDate = data.order.paused_until ?? '';
        this.deliveries = data.deliveries;
        this.username = data.username || '';
        this.institution = data.profile.institution || '';
      } else {
        displayMessage(message, true, this.$store);
      }

      this.loading = false;
    }
  }

  async savePause(val: string) {
    this.showPauseModal = false;
    this.loading = true;

    const newOrder = {
      order: {
        paused_until: val,
      },
    };
    const response = await this.orderService.updateUserOrder(
      +this.userId,
      JSON.stringify(newOrder)
    );
    if (response.statusCode === 200) {
      this.showPauseModalConfirmation = true;
    }

    this.fillUserOrdersData();
    this.loading = false;
  }

  async saveNewQuantity(value: number) {
    if (value <= 0) {
      displayMessage('Ungültiger Wert', true, this.$store);
      return;
    }

    if (value > this.maxQuantity) {
      displayMessage(`Sie können maximal ${this.maxQuantity} Stück bestellen.`, true, this.$store);
      return;
    }

    this.loading = true;
    this.showQuantityModal = false;

    const newOrder = {
      order: {
        quantity: value,
      },
    };
    const response = await this.orderService.updateUserOrder(
      +this.userId,
      JSON.stringify(newOrder)
    );
    if (response.statusCode === 200) {
      this.showQuantityModalConfirmation = true;
    }

    this.fillUserOrdersData();
    this.loading = false;
  }

  async saveNewMaxQuantity(value: number) {
    if (value <= 0) {
      displayMessage('Ungültiger Wert', true, this.$store);
      return;
    }

    this.loading = true;
    this.showMaxQuantityModal = false;

    const newOrder = {
      order: {
        max_quantity: value,
      },
    };
    const response = await this.orderService.updateUserOrder(
      +this.userId,
      JSON.stringify(newOrder)
    );
    if (response.statusCode === 200) {
      this.showMaxQuantityModalConfirmation = true;
    }

    this.fillUserOrdersData();
    this.loading = false;
  }

  get nextOrderAfterPause(): Date {
    const date = new Date(`${this.newPausedUntilDate} 12:00:00`);
    date.setDate(date.getDate() - 2);
    return date;
  }

  get deliveryDateRangeAfterPause(): Date[] {
    if (!this.newPausedUntilDate) return [];

    const firstDate = new Date(`${this.newPausedUntilDate} 12:00:00`);
    firstDate.setDate(firstDate.getDate() + 3);

    const lastDate = new Date(`${this.newPausedUntilDate} 12:00:00`);
    lastDate.setDate(lastDate.getDate() + 7);

    return [firstDate, lastDate];
  }

  get nextDeliveryDateRange(): Date[] {
    if (!this.nextDeliveryDate) return [];

    const firstDate = new Date(`${this.nextDeliveryDate} 12:00:00`);
    firstDate.setDate(firstDate.getDate() + 5);

    const lastDate = new Date(`${this.nextDeliveryDate} 12:00:00`);
    lastDate.setDate(lastDate.getDate() + 9);

    return [firstDate, lastDate];
  }
}
