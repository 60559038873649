import { AxiosError } from 'axios';
import ApiService from '../api-service';
import { PaginationOrder, UserOrders, Order } from '@/common/models/order';
import { PaginationParams } from '@/common/models/pagination';

export interface DeliveryResponse {
  statusCode: number;
  data: PaginationOrder | undefined;
  message: string;
}

export default class OrderService extends ApiService {
  async getUserOrderAndDeliveries(
    userId: number,
    params?: PaginationParams
  ): Promise<{ statusCode: number; data: UserOrders | undefined; message: string }> {
    try {
      const response = await this.axios.get(`/users/${userId}/orders/deliveries`, { params });
      return { statusCode: response.status, data: response.data, message: '' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async updateUserOrder(
    userId: number,
    order: string
  ): Promise<{ statusCode: number; data: UserOrders | undefined; message: string }> {
    try {
      const response = await this.axios.patch(
        `users/${userId}/orders/deliveries`,
        JSON.parse(order)
      );
      return { statusCode: response.status, data: response.data, message: '' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async getOrders(params?: PaginationParams): Promise<DeliveryResponse> {
    try {
      const data = await this.axios.get<PaginationOrder>(`/admin/orders`, {
        params,
      });
      return {
        message: 'success',
        statusCode: 200,
        data: data.data,
      };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async getOrdersCSV(
    value: string
  ): Promise<{ statusCode: number; data: string | undefined; message: string }> {
    try {
      const response = await this.axios.get(`/admin/orders/export?search=${value}`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      return { statusCode: response.status, data: response.data, message: 'Datei heruntergeladen' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async getMaxQuantity(userId: number): Promise<number> {
    try {
      const response = await this.axios.get<Order>(`/users/${userId}/orders`);

      return response.data.max_quantity ?? 0;
    } catch (error) {
      return 0;
    }
  }
}
